import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {combineLatest} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';
import {UserFleetPermission} from '@models/boats-fleet/boats-fleet-permission';

import {BoatFleetDetailsService} from '../../boat-fleet-wrapper/components/services/boat-fleet-details.service';
import {BoatFleetPermissionsService} from '../services/boat-fleet-permissions.service';

@Directive({
  selector: '[appBoatFleetPermissions]',
})
export class BoatFleetPermissionsDirective extends DestroySubscription {

  private permission: UserFleetPermission | UserFleetPermission[];
  private viewRef: EmbeddedViewRef<any> | null = null;
  private readonly templateRef: TemplateRef<any>;

  @Input()
  set appBoatFleetPermissions(permission: UserFleetPermission | UserFleetPermission[]) {
    if (!permission) {
      this.createView();
      return;
    }

    if (!this.permission) {
      this.permission = permission;
      this.initByAsyncFleet();
    }
  }

  /**
   * use as param after set permission
   *
   * @param fleetId - set fleet id in common list
   * @link https://angular.io/guide/structural-directives#structural-directive-syntax-reference
   * @link https://stackoverflow.com/questions/41789702/how-to-use-angular-structural-directive-with-multiple-inputs
   */
  @Input() set appBoatFleetPermissionsFleetId(fleetId: number) {
    if (!fleetId) {
      return;
    }
    this.initByFleetId(fleetId);
  }

  constructor(
    private readonly ref: TemplateRef<any>,
    private readonly boatFleetsPermissionsService: BoatFleetPermissionsService,
    private readonly boatFleetDetailsService: BoatFleetDetailsService,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.templateRef = ref;
  }

  private initByAsyncFleet(): void {
    try {
      const fleetId$ = this.boatFleetDetailsService.fleetId$;
      if (!fleetId$) {
        return;
      }
      const permissions$ = this.boatFleetsPermissionsService.fleetPermissions$;
      combineLatest([
        fleetId$,
        permissions$,
      ]).pipe(
        map(([fleetId]) => this.boatFleetsPermissionsService.findPermissionsByFleet(fleetId)),
        takeUntil(this.destroyStream$),
      ).subscribe(fleetPermissions => {
        this.checkPermissions(fleetPermissions);
      });
    } catch (e) {
      return;
    }
  }

  private initByFleetId(fleetId: number): void {
    const permissions$ = this.boatFleetsPermissionsService.fleetPermissions$;
    permissions$.pipe(
      map(() => this.boatFleetsPermissionsService.findPermissionsByFleet(fleetId)),
      takeUntil(this.destroyStream$),
    ).subscribe(fleetPermissions => {
      this.checkPermissions(fleetPermissions);
    });
  }

  private checkPermissions(fleetPermissions: UserFleetPermission[]): void {
    const isPermitted = this.boatFleetsPermissionsService.isHavePermission(fleetPermissions, this.permission);
    if (!isPermitted) {
      this.clearView();
      return;
    }
    this.createView();
  }

  private clearView(): void {
    this.vcr.clear();
    this.viewRef = null;
  }

  private createView(): void {
    if (!this.viewRef) {
      this.viewRef = this.vcr.createEmbeddedView(this.templateRef);
      this.cdr.detectChanges();
    }
  }

}
