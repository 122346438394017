import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, switchMap} from 'rxjs/operators';

import {FleetMaintenanceBoat} from '@models/boat-maintenance';
import {FleetShortItemDto} from '@models/boats-fleet';
import {CurrencyDto} from '@models/directories';
import {FleetMaintenanceApiService} from '@services/boats-fleet/fleet-maintenance-api.service';
import {FleetManagementApiService} from '@services/boats-fleet/fleet-management-api.service';

import {BoatFleetsListService} from './boat-fleets-list.service';

@Injectable()
export class BoatFleetDetailsService {

  private selectedFleetId$: BehaviorSubject<number>;
  fleetId$: Observable<number>;
  currency$: Observable<CurrencyDto | null>;
  fleetList$: Observable<FleetShortItemDto[]>;
  boats$: Observable<FleetMaintenanceBoat[]>;

  get selectedFleetId(): number {
    return this.selectedFleetId$.value;
  }

  constructor(
    private readonly fleetManagementApiService: FleetManagementApiService,
    private readonly boatFleetsListService: BoatFleetsListService,
    private readonly fleetMaintenanceApiService: FleetMaintenanceApiService,
  ) {
  }

  init(fleetId: number): void {
    this.selectedFleetId$ = new BehaviorSubject(fleetId);
    this.fleetId$ = this.selectedFleetId$.asObservable().pipe(distinctUntilChanged());
    this.fleetList$ = this.boatFleetsListService.fleetList$;
    this.currency$ = this.getFleetCurrency(this.fleetId$, this.fleetList$);
    this.boats$ = this.getFleetBoats().pipe(shareReplay(1));
  }

  destroy(): void {
    this.selectedFleetId$.complete();
  }

  updateFleetId(fleetId: number): void {
    this.selectedFleetId$.next(fleetId);
  }

  getFleetCurrency(
    selectedFleetId$: Observable<number>,
    list$: Observable<FleetShortItemDto[]>,
  ): Observable<CurrencyDto | null> {
    return combineLatest([
      selectedFleetId$,
      list$,
    ]).pipe(
      map(([fleetId, list]) => list.find(item => item.id === fleetId)?.currency || null),
      shareReplay(1),
    );
  }

  private getFleetBoats(): Observable<FleetMaintenanceBoat[]> {
    return this.selectedFleetId$.asObservable().pipe(
      switchMap(fleet => this.fleetMaintenanceApiService.getFleetBoatList(fleet).pipe(
        // startWith([]),
      )),
    );
  }
}
