import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {combineLatest} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';
import {UserFleetPermission} from '@models/boats-fleet';

import {BoatFleetDetailsService} from '../../boat-fleet-wrapper/components/services/boat-fleet-details.service';
import {
  BoatFleetPermissionsStubComponent,
} from '../components/boat-fleet-permissions-stub/boat-fleet-permissions-stub.component';
import {BoatFleetPermissionsService} from '../services/boat-fleet-permissions.service';

@Directive({
  selector: '[appBoatFleetPermissionsStub]',
})
export class BoatFleetPermissionsStubDirective extends DestroySubscription {

  private contentPermission: UserFleetPermission | UserFleetPermission[];
  private viewRef: EmbeddedViewRef<any> | null = null;
  private stubRef: ComponentRef<BoatFleetPermissionsStubComponent> | null = null;
  private readonly templateRef: TemplateRef<any>;

  @Input()
  set appBoatFleetPermissionsStub(permission: UserFleetPermission | UserFleetPermission[]) {
    if (!permission) {
      this.createView();
      return;
    }

    if (!this.contentPermission) {
      this.contentPermission = permission;
      this.init();
    }
  }

  constructor(
    private readonly ref: TemplateRef<any>,
    private readonly boatFleetsPermissionsService: BoatFleetPermissionsService,
    private readonly boatFleetDetailsService: BoatFleetDetailsService,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.templateRef = ref;
  }

  private init(): void {
    combineLatest([
      this.boatFleetDetailsService.fleetId$,
      this.boatFleetsPermissionsService.fleetPermissions$,
    ]).pipe(
      map(([fleetId]) => this.boatFleetsPermissionsService.findPermissionsByFleet(fleetId)),
      takeUntil(this.destroyStream$),
    ).subscribe(userFleetPermission => this.checkPermissions(userFleetPermission));
  }

  private checkPermissions(userFleetPermission: UserFleetPermission[]): void {
    const isPermitted = this.boatFleetsPermissionsService.isHavePermission(userFleetPermission, this.contentPermission);
    if (!isPermitted) {
      this.clearView();
      return;
    }
    this.createView();
  }

  private clearView(): void {
    if (this.stubRef) {
      return;
    }
    this.vcr.clear();
    this.viewRef = null;
    this.stubRef = this.vcr.createComponent(BoatFleetPermissionsStubComponent);
    this.cdr.detectChanges();
  }

  private createView(): void {
    if (this.viewRef) {
      return;
    }
    this.vcr.clear();
    this.stubRef = null;
    this.viewRef = this.vcr.createEmbeddedView(this.templateRef);
    this.cdr.detectChanges();
  }

}
