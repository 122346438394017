import {Injectable} from '@angular/core';
import {cleanObject} from '@idapgroup/js-object-utils';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {
  FleetMaintenanceBoatsListItem,
  FleetMaintenanceStatisticPayload,
  FleetMaintenanceTasksAlertsDto,
} from '@features/boats-fleet/shared/models/fleet-maintenance';
import {
  FleetBoatSelectionFilterData,
} from '@features/boats-fleet/shared/modules/fleet-boat-selection-modal/models/fleet-boat-selection-modal-data';
import {FleetNotesDto} from '@features/boats-fleet/shared/modules/fleet-notes/models/fleet-notes';
import {buildURLParams} from '@helpers/build-url-params';
import {httpParamsFromObject} from '@helpers/http-params';
import {BoatMaintenanceStatisticDto, BoatMaintenanceTasksInfoDto, FleetMaintenanceBoat} from '@models/boat-maintenance';
import {ResponseDto} from '@models/response';

@Injectable({
  providedIn: 'root',
})
export class FleetMaintenanceApiService {

  private readonly apiUrl = 'api/fleet-maintenance';

  constructor(private readonly http: TransferHttpService) { }

  getFleetBoatList(fleet: number): Observable<FleetMaintenanceBoat[]> {
    const params = httpParamsFromObject({fleet});
    return this.http.get<ResponseDto<FleetMaintenanceBoat[]>>(
      `${this.apiUrl}/get-boat-list`,
      {params},
    ).pipe(
      map(({data}) => data),
    );
  }

  getFleetMaintenanceStatistic(payload: FleetMaintenanceStatisticPayload): Observable<BoatMaintenanceStatisticDto> {
    const params = buildURLParams(cleanObject(payload));
    return this.http.get<ResponseDto<BoatMaintenanceStatisticDto>>(
      `${this.apiUrl}/get-task-info`,
      {params},
    ).pipe(
      map(({data}) => data),
    );
  }

  getFleetTasksInfo(fleet: number): Observable<BoatMaintenanceTasksInfoDto> {
    const params = httpParamsFromObject({fleet});
    return this.http.get<ResponseDto<BoatMaintenanceTasksInfoDto>>(
      `${this.apiUrl}/get-fleet-tasks-info`,
      {params},
    ).pipe(
      map(({data}) => data),
    );
  }

  getFleetModalBoats(payload: FleetBoatSelectionFilterData): Observable<FleetMaintenanceBoatsListItem[]> {
    const params = buildURLParams(cleanObject(payload));
    return this.http.get<ResponseDto<FleetMaintenanceBoatsListItem[]>>(
      `${this.apiUrl}/get-modal-boats`,
      {params},
    ).pipe(
      map(({data}) => data),
    );
  }

  getFleetTasksAlerts(fleet: number): Observable<FleetMaintenanceTasksAlertsDto> {
    const params = httpParamsFromObject({fleet});
    return this.http.get<ResponseDto<FleetMaintenanceTasksAlertsDto>>(
      `${this.apiUrl}/get-task-statistic-open-expired`,
      {params},
    ).pipe(
      map(({data}) => data),
    );
  }

  getNotesList(payload: {fleet: number, search: string | null}): Observable<FleetNotesDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<FleetNotesDto>>(
      `${this.apiUrl}/get-notes`,
      {params},
    ).pipe(
      map(({data}) => data),
    );
  }

  addNote(payload: {fleet: number, text: string}): Observable<ResponseDto<unknown>> {
    return this.http.post(`${this.apiUrl}/add-note`, payload);
  }

  editNote(payload: {fleet: number, text: string, note: number,}): Observable<ResponseDto<unknown>> {
    return this.http.put(`${this.apiUrl}/update-note`, payload);
  }

  deleteNote(payload: {fleet: number, note: number}): Observable<ResponseDto<unknown>> {
    const params = httpParamsFromObject(payload);
    return this.http.delete(
      `${this.apiUrl}/delete-note`,
      {params},
    );
  }
}
