import {UserFleetPermission} from '@models/boats-fleet';

export const FLEET_DASHBOARD_PERMISSIONS: UserFleetPermission[] = [
  'perm_boat_activity',
  'perm_weather_and_alerts',
  'perm_boat_map',
  'perm_expenses',
  'perm_charters',
  'perm_job_offers',
];
