import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NoDataModule} from '@components/no-data/no-data.module';
import {SeazoneIconRegistryService, statsIcon} from '@modules/seazone-icons';
import {TranslocoModule} from '@ngneat/transloco';

import {
  BoatFleetPermissionsStubComponent,
} from './components/boat-fleet-permissions-stub/boat-fleet-permissions-stub.component';
import {BoatFleetPermissionsStubDirective} from './directives/boat-fleet-permissions-stub.directive';
import {BoatFleetPermissionsDirective} from './directives/boat-fleet-permissions.directive';

@NgModule({
  declarations: [
    BoatFleetPermissionsDirective,
    BoatFleetPermissionsStubDirective,
    BoatFleetPermissionsStubComponent,
  ],
  imports: [
    CommonModule,
    NoDataModule,
    TranslocoModule,
  ],
  exports: [
    BoatFleetPermissionsDirective,
    BoatFleetPermissionsStubDirective,
  ],
})
export class BoatFleetPermissionsModule {
  constructor(
    private readonly s: SeazoneIconRegistryService,
  ) {
    this.s.registerIcons([statsIcon]);
  }
}
