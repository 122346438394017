import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-boat-fleet-permissions-stub',
  templateUrl: './boat-fleet-permissions-stub.component.html',
  styleUrls: ['./boat-fleet-permissions-stub.component.scss'],
})
export class BoatFleetPermissionsStubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
