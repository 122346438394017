import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {shareReplay, startWith, switchMap} from 'rxjs/operators';

import {FleetShortItemDto} from '@models/boats-fleet';
import {FleetManagementApiService} from '@services/boats-fleet/fleet-management-api.service';

@Injectable()
export class BoatFleetsListService {

  private loadList$: Subject<void>;
  fleetList$: Observable<FleetShortItemDto[]>;

  constructor(
    private readonly fleetManagementApiService: FleetManagementApiService,
  ) {
  }

  init(): void {
    this.loadList$ = new Subject<void>();
    this.fleetList$ = this.getFleetShortList();
  }

  destroy(): void {
    this.loadList$.complete();
  }

  updateList(): void {
    this.loadList$.next();
  }

  private getFleetShortList(): Observable<FleetShortItemDto[]> {
    return this.loadList$.pipe(
      startWith(null),
      switchMap(() => this.fleetManagementApiService.getFleetsShortList()),
      shareReplay(1),
    );
  }
}
